<template>
  <Public>
    <h1 class="mt-0 mb-3">{{ $t("privacy.title") }}</h1>
    <div v-html="$t('privacy.content-html')"></div>
  </Public>
</template>

<script>
import Public from "@/views/layouts/public";

export default {
  metaInfo() {
    return {
      title: this.$t("app.title") + " | " + this.$t("page.privacy"),
    };
  },
  components: {
    Public,
  },
};
</script>

<style lang="scss" scoped></style>
