<template>
  <div class="page-auth">
    <Topbar public />

    <div class="content-page">
      <div class="content">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-sm-12 col-lg-8 my-4 mx-auto mx-w">
              <slot />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Topbar from "@/components/Topbar";
import Footer from "@/components/Footer";

export default {
  components: {
    Topbar,
    Footer,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
